
<template>
  <div class="get-content-page">
    <h6 class="ym-title">
      <el-link class="ym-title-link" href="#/listFowlOuts">出栏信息 <i class="el-icon-arrow-right"></i> </el-link>
      <span>{{ title }}</span>
    </h6>
    <div class="ym-panel-general">
      <!-- 标题栏 -->
      <el-row>
        <el-col :span="12">
          <div class="grid-content bg-purple-dark">
            <span>基本信息</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-dark grid-btn">
            <el-button type="primary" plain round size="mini" @click="goTo()">出栏明细</el-button>
          </div>
        </el-col>
      </el-row>
      <!-- basic第一行 -->
      <el-row>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">出栏编号：</span>
            <span class="basic-infor">{{basicInfor.outNum}}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">产品名称：</span>
            <span class="basic-infor">{{basicInfor.productName}}</span>
          </div>
        </el-col>
      </el-row>
      <!-- basic第二行 -->
      <el-row>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">产品批次编号：</span>
            <span class="basic-infor">{{basicInfor.batchNum}}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">下游商户：</span>
            <span class="basic-infor">{{basicInfor.outCompanyName}}</span>
          </div>
        </el-col>
      </el-row>
      <!-- basic第三行 -->
      <el-row>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">出栏时间：</span>
            <span class="basic-infor">{{basicInfor.createTime}}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">出栏明细：</span>
            <span class="basic-infor" v-if="outDetails.length===1">{{outDetails[0]}}</span>
            <span class="basic-infor" v-else-if="outDetails.length>1">{{outDetails[0]}}，{{outDetails[1]}}，...</span>
          </div>
        </el-col>
      </el-row>
      <!-- basic第四行 -->
      <el-row>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">出栏数量：</span>
            <span class="basic-infor">{{basicInfor.outQty}}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="basic-content basic-last">
            <span class="basic-title">备注：</span>
            <span class="basic-infor">{{basicInfor.remark}}</span>
          </div>
        </el-col>
      </el-row>
       <!-- 标题栏 -->
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            区块信息
          </div>
        </el-col>
      </el-row>
      <!-- block第一行 -->
      <div class="chain-info">
      <el-row>
        <el-col :span="4">
          <div class="block-content">
            <span class="block-dian">·</span>
            <span class="block-title">区块号：</span>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="block-content">
            <span v-if="basicInfor.blockChain" class="block-infor">{{basicInfor.blockChain.blockNum}}</span>
          <span v-else class="block-infor">~暂无上链信息~</span>
          </div>
        </el-col>
      </el-row>
      <!-- block第二行 -->
      <el-row>
        <el-col :span="4">
          <div class="block-content">
            <span class="block-dian">·</span>
            <span class="block-title">上链日期：</span>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="block-content">
            <span v-if="basicInfor.blockChain" class="block-infor">{{basicInfor.blockChain.createTime}}</span>
            <span v-else class="block-infor">~暂无上链信息~</span>
          </div>
        </el-col>
      </el-row>
      <!-- block第三行 -->
      <el-row>
        <el-col :span="4">
          <div class="block-content">
            <span class="block-dian">·</span>
            <span class="block-title">所属分组：</span>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="block-content">
            <span v-if="basicInfor.blockChain" class="block-infor">{{basicInfor.blockChain.groupName}}</span>
            <span v-else class="block-infor">~暂无上链信息~</span>
          </div>
        </el-col>
      </el-row>
      <!-- block第四行 -->
      <el-row>
        <el-col :span="4">
          <div class="block-content">
            <span class="block-dian">·</span>
            <span class="block-title">唯一标识：</span>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="block-content">
            <span v-if="basicInfor.blockChain" class="block-infor">{{basicInfor.blockChain.resultHash}}</span>
            <span v-else class="block-infor">~暂无上链信息~</span>
          </div>
        </el-col>
      </el-row>
      <!-- block第五行 -->
      <el-row class="last-row">
        <el-col :span="4">
          <div class="block-content">
            <span class="block-dian">·</span>
            <span class="block-title">上链地址：</span>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="block-content">
            <span v-if="basicInfor.blockChain" class="block-infor">{{basicInfor.blockChain.chainUrl}}</span>
            <span v-else class="block-infor">~暂无上链信息~</span>
          </div>
        </el-col>
      </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '详情',
      // 表单对象
      basicInfor: {},
      outDetails: []

    }
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  watch: {},
  created() {
    if (this.id) {
      this.title = '详情'
      this.get()
    }
  },
  methods: {
    goTo() {
      this.$router.push({ path: '/listFowlOuts/fowlOutList', query: { id: this.id, afcBatchId: this.basicInfor.afcBatchId }})
    },
    goBack() {
      this.$router.push('/listFowlOuts')
    },
    // 查询
    async get() {
      const params = {
        afcBatchOutId: this.id
      }
      const { data } = await this.$http.fowlOutsInfor.getFowlOut(params)
      if (data.code !== '0') return this.$message.error(data.msg || '查询失败')
      // 回显表单
      console.log('查询数据：', data.data)
      this.basicInfor = data.data
      const afcBatchVervels = data.data.afcBatchVervels
      for (var item of afcBatchVervels) {
        this.outDetails.push(item.afcNum) // 返显出栏明细
      }
    }

  }
}
</script>

<style lang="scss">
.get-content-page {
  .warning {
    color: #e6a23c !important;
  }
  .basic-content{
    font-size:14px;
    line-height: 20px;
    padding: 20px 24px 0 24px;
    display: flex;
    flex-direction: row;
    .basic-title{
      white-space: nowrap;
      color:rgb(29, 29, 29);
    }
    .basic-infor{
      color:rgba(102,102,102,1);
      width: 500px;
    }
  }
  .basic-last{
    margin-bottom: 32px;
  }
  .block-content{
    font-size:14px;
    line-height: 20px;
    padding: 14px 24px 14px 24px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #E8E8E8;
    .block-dian{
      font-size: 14px;
      color: #3E6DF5;
      font-weight: 900;
      margin-right: 10px;
    }
    .block-title{
      color:rgb(29, 29, 29);
    }
    .block-infor{
      color:#666666;
    }
  }

  .last-row{
    margin-bottom: 24px;
  }

  //下载文件样式
  .download-title{
    color:#3e6df5;
  }
  .download-title:hover{
    color:#668bfa;
    text-decoration: underline;
  }

.chain-info{
  .el-row{
    border-bottom: 1px solid #E8E8E8;
  }
  .block-content {
    border:0;
    word-break: break-all;
  }
}
}
</style>
